/*
下記参考にstyle調整を行う
https://gist.github.com/igoro00/99e9d244677ccafbf39667c24b5b35ed#file-date-picker-css
https://qiita.com/ma23305/items/ffc210de40eaac390be0
*/

.react-datepicker {
  width: 100%;
  border: none;
  border-radius: unset;
  font-size: 1.4rem;
}

.react-datepicker__month {
  margin: 0px;
}

.react-datepicker__navigation {
  top: 48px;
}

.react-datepicker__navigation-icon:before,
.react-datepicker__navigation:hover *::before {
  border-color: #666666;
  border-width: 2px 2px 0 0;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #fff; /* デフォだと今日の日付に色がつくので白に上書き */
}
.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select {
  margin: 0 8px;
}

.react-datepicker__month-container {
  width: 100%;
}

div.react-datepicker > div.react-datepicker__month-container:nth-of-type(2) > .react-datepicker__month > .react-datepicker__week:nth-child(n+5) .react-datepicker__day--outside-month {
  visibility: hidden;
}

div.react-datepicker > div.react-datepicker__month-container:nth-of-type(3) > .react-datepicker__header {
  display: none;
}

div.react-datepicker > div.react-datepicker__month-container:nth-of-type(3) > .react-datepicker__month > .react-datepicker__week:nth-child(-n+1) .react-datepicker__day--outside-month {
  visibility: hidden;
}

div.react-datepicker > div.react-datepicker__month-container:nth-of-type(3) > .react-datepicker__month > .react-datepicker__week:nth-child(n+3) .react-datepicker__day {
  display: none;
}

.react-datepicker__day-names {
  display: flex;
  padding: 4px 0px 4px 0px;
}

.react-datepicker__day-name {
  flex: auto;
  color: #666666;
  font-size: 10px;
}

div.react-datepicker__day-name:first-child {
  color: #E85298;
}

.react-datepicker__week {
  display: flex;
}

.react-datepicker__day {
  flex: auto;
}

.react-datepicker__header {
  background: #FFF;
  border-bottom: none;
  padding: 0px;
  padding-bottom: 8px;
}

.react-datepicker__day {
  color: #666666;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  background-color: transparent;
  z-index: 1;
  margin: 0px;
  padding: 7px 0px;
}

.react-datepicker__day:hover::after {
  content: ' ';
  border-radius: 50%;
  background-color: #FBE2EE;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0px;
  left: calc(50% - 17px);
  z-index: -1;
}

.react-datepicker__day:hover {
  background-color: transparent;
}

.react-datepicker__day--selected {
  color: #FFF;
  position: relative;
  background-color: transparent;
  z-index: 1;
}

.react-datepicker__day--selected:hover {
  background-color: transparent;
}

.react-datepicker__day--selected::after {
  content: ' ';
  border-radius: 50%;
  background-color: #E85298;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0px;
  left: calc(50% - 17px);
  z-index: -1;
}

.react-datepicker__day--selected:hover::after {
  content: ' ';
  border-radius: 50%;
  background-color: #E85298;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0px;
  left: calc(50% - 17px);
  z-index: -1;
}

.react-datepicker__day--disabled {
  color: #CCC9C9;
}

.react-datepicker__day--disabled:hover {
  background-color: transparent;
}

.react-datepicker__day--disabled:hover::after {
  background-color: transparent;
}
